<template>
  <div>
    <div class="text-center">
      <div class="container text-center mb-2">
        <ul class="progressbar text-center">
          <li class="active" id="step-1"><span>Business Info</span></li>
          <li class="" id="step-2"><span>Additional Info</span></li>
          <!-- <li id="step-3"><span>Create User</span></li> -->
        </ul>
      </div>
    </div>

    <!-- Start of Entity information section -->

    <div
      class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0"
      id="BusinessInfo"
    >
      <div class="row">
        <div class="col-md-3">
          <button
            class="btn btn-default btn-sm mt-4 px-5"
            @click="this.$router.go(-1)"
          >
            Back
          </button>
        </div>
      </div>

      <div class="card-body px-lg-5 py-lg-3 row">
        <div class="col-md-12">
          <h2 class>Business Information</h2>
          <hr class="my-0 mb-3" />
          <div class="ml-3 row">
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >What's the name of your business or organization?</label
              >
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="name of the business"
                type="text"
                v-model="post_data.name"
              />
              <label
                v-for="error in errors.name"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="col-md-6"></div>
            <div class="form-group col-md-6">
              <label class="form-control-label">Email</label>
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="email"
                v-model="post_data.email"
                type="email"
              />
              <label
                v-for="error in errors.email"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="col-md-6">
              <phone-input
                :value="phone"
                :errors="errors.phone"
                label="Phone"
                placeholder="Enter your phone number"
                @type="handle_phone"
              />
            </div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >Where is your business located?</label
              >
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="Address"
                v-model="post_data.address"
                name="address"
                type="text"
              />
              <label
                v-for="error in errors.address"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="col-md-6"></div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >Provide business website url if any, otherwise leave this field blank.</label
              >
              <input
                class="form-control form-control-alternative"
                placeholder="Enter the website URL eg. https://www.hexakomb.com"
                v-model="post_data.website"
                type="text"
              />
              <label
                v-for="error in errors.website"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-10">
              <label class="form-control-label"
                >Briefly describe how your business promotes Women
                Empowerment.</label
              >
              <span class="ml-1 text-danger">*</span>
              <textarea
                class="form-control form-control-alternative"
                placeholder="Describe your business impact towards Women"
                rows="4"
                v-model="post_data.description"
              ></textarea>
              <label
                v-for="error in errors.description"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-10">
              <label class="form-control-label"
                >Briefly describe what your business does.</label
              >
              <span class="ml-1 text-danger">*</span>
              <textarea
                class="form-control form-control-alternative"
                placeholder="Briefly describe your business"
                rows="4"
                v-model="post_data.about"
              ></textarea>
              <label
                v-for="error in errors.about"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                @click="nextToAdditional"
                class="btn btn-block btn-md btn-default my-4"
              >
                next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Business information section -->

    <!-- Start of  Additional information section -->
    <div
      class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0 mt-4 d-none"
      id="AdditionalInfo"
    >
      <div class="card-body px-lg-5 py-lg-3 row">
        <div class="col-md-12 mt-3">
          <h2 class>Additional Information</h2>
          <hr class="my-0 mb-3" />
          <div class="ml-3 row">
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >What Industry does the business belong to?</label
              >
              <span class="ml-1 text-danger">*</span>
              <select
                class="form-control form-control-alternative"
                v-model="industry"
                name="industry"
              >
                <option
                  v-for="industry in industries"
                  :key="industry.uuid"
                  :value="industry.uuid"
                >
                  {{ industry.name }}
                </option>
                <option value="other">Other (please specify)</option>
              </select>
              <label
                v-for="error in errors.industry"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div v-if="!show_other" class="col-md-6"></div>
            <div v-if="show_other" class="form-group col-md-6">
              <label class="form-control-label"
                >Tell us the industry/sector your business belongs to</label
              >
              <input
                class="form-control form-control-alternative"
                placeholder="Enter the sector/industry your business belongs to"
                v-model="post_data.other_industry"
                type="text"
              />
              <label
                v-for="error in errors.other_industry"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >How would you describe your business orientation?</label
              >
              <span class="ml-1 text-danger">*</span>
              <div class="d-flex">
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="orientation"
                    name="orientation"
                    v-model="business_orientation"
                    value="service"
                    type="radio"
                  />
                  <label class="custom-control-label" for="orientation"
                    >Service oriented</label
                  >
                </div>
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="orientation2"
                    name="orientation"
                    v-model="business_orientation"
                    value="product"
                    type="radio"
                  />
                  <label class="custom-control-label" for="orientation2"
                    >Product oriented</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    id="orientation3"
                    name="orientation"
                    v-model="business_orientation"
                    value="both"
                    type="radio"
                  />
                  <label class="custom-control-label" for="orientation3"
                    >Both</label
                  >
                </div>
              </div>
              <label
                v-for="error in errors.business_orientation"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="col-md-6"></div>
            <div
              v-if="post_data.is_product_related"
              class="form-group col-md-6"
            >
              <label class="form-control-label"
                >How many products does the business produce?</label
              >
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="The number of products"
                type="number"
                min="0"
                v-model="post_data.no_of_products"
              />
              <label
                v-for="error in errors.no_of_products"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div v-if="post_data.is_product_related && !post_data.is_service_related" class="col-md-6"></div>
            <div
              v-if="post_data.is_service_related"
              class="form-group col-md-6"
            >
              <label class="form-control-label"
                >How many services does the business offer?</label
              >
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="number of services"
                min="0"
                v-model="post_data.no_of_services"
                type="number"
              />
              <label
                v-for="error in errors.no_of_services"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div v-if="!post_data.is_product_related && post_data.is_service_related" class="col-md-6"></div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >Total No of Employees or Participants</label
              >
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="number of employees or participants"
                v-model="post_data.no_of_employees"
                type="number"
              />
              <label
                v-for="error in errors.no_of_employees"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >No of Women Employees or Participants</label
              >
              <span class="ml-1 text-danger">*</span>
              <input
                class="form-control form-control-alternative"
                placeholder="number of women employees or participant"
                v-model="post_data.no_of_women_employees"
                type="number"
              />
              <label
                v-for="error in errors.no_of_women_employees"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div
              v-if="post_data.is_product_related"
              class="form-group col-md-6 mr-3"
            >
              <label class="form-control-label"
                >Does your business do retail or wholesale?</label
              >
              <select
                class="form-control form-control-alternative"
                v-model="post_data.business_type"
                name="industry"
              >
                <option value="Retailer">Retail</option>
                <option value="Wholesaler">Wholesale</option>
              </select>
              <label
                v-for="error in errors.business_type"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-6 mr-3">
              <label class="form-control-label"
                >When was your business established?</label
              >
              <span class="ml-1 text-danger">*</span>
              <select
                class="form-control form-control-alternative"
                v-model="post_data.starting_year"
                name="start_year"
              >
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
              <label
                v-for="error in errors.starting_year"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >Is your business registered with RDB?</label
              >
              <span class="ml-1 text-danger">*</span>
              <div class="d-flex">
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="rdb"
                    name="rdb"
                    v-model="post_data.is_rdb_registered"
                    :value="true"
                    type="radio"
                  />
                  <label class="custom-control-label" for="rdb">Yes</label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    id="rdb2"
                    name="rdb"
                    v-model="post_data.is_rdb_registered"
                    :value="false"
                    type="radio"
                  />
                  <label class="custom-control-label" for="rdb2">No</label>
                </div>
              </div>
              <label
                v-for="error in errors.is_rdb_registered"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div
              v-if="post_data.is_product_related"
              class="form-group col-md-6"
            >
              <label class="form-control-label"
                >Does your business have a RBS certificate?</label
              >
              <span class="ml-1 text-danger">*</span>
              <div class="d-flex">
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="rbs"
                    name="rbs"
                    v-model="post_data.has_rbs_certificate"
                    :value="true"
                    type="radio"
                  />
                  <label class="custom-control-label" for="rbs">Yes</label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    id="rbs2"
                    name="rbs"
                    v-model="post_data.has_rbs_certificate"
                    :value="false"
                    type="radio"
                  />
                  <label class="custom-control-label" for="rbs2">No</label>
                </div>
              </div>
              <label
                v-for="error in errors.has_rbs_certificate"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >What is the target market of your business?</label
              >
              <div class="d-flex">
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="target"
                    name="target"
                    v-model="post_data.target_market"
                    :value="'Local'"
                    type="radio"
                  />
                  <label class="custom-control-label" for="target">Local</label>
                </div>
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="target2"
                    name="target"
                    v-model="post_data.target_market"
                    :value="'Global'"
                    type="radio"
                  />
                  <label class="custom-control-label" for="target2"
                    >Global</label
                  >
                </div>
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="target3"
                    name="target"
                    v-model="post_data.target_market"
                    :value="'Both'"
                    type="radio"
                  />
                  <label class="custom-control-label" for="target3">Both</label>
                </div>
              </div>
              <label
                v-for="error in errors.target_market"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div
              v-if="post_data.is_product_related"
              class="form-group col-md-6"
            >
              <label class="form-control-label"
                >Does your business do delivery?</label
              >
              <div class="d-flex">
                <div class="custom-control custom-radio mr-3">
                  <input
                    class="custom-control-input"
                    id="delivery"
                    name="delivery"
                    v-model="post_data.does_delivery"
                    :value="true"
                    type="radio"
                  />
                  <label class="custom-control-label" for="delivery">Yes</label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    id="delivery2"
                    name="delivery"
                    v-model="post_data.does_delivery"
                    :value="false"
                    type="radio"
                  />
                  <label class="custom-control-label" for="delivery2">No</label>
                </div>
              </div>
              <label
                v-for="error in errors.does_delivery"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="form-group col-md-6">
              <label class="form-control-label"
                >which electronic payment methods does your business
                accepts?</label
              >
              <span class="ml-1 text-danger">*</span>
              <div class="ml-4">
                <div class="custom-control custom-checkbox mr-3">
                  <input
                    type="checkbox"
                    v-model="post_data.accepted_electronic_payments"
                    :value="'Momo Pay'"
                    class="custom-control-input"
                    id="momopay"
                  />
                  <label class="custom-control-label" for="momopay"
                    >Momo Pay</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-3">
                  <input
                    type="checkbox"
                    v-model="post_data.accepted_electronic_payments"
                    :value="'Mobile Money'"
                    class="custom-control-input"
                    id="mobilemoney"
                  />
                  <label class="custom-control-label" for="mobilemoney"
                    >Mobile Money</label
                  >
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    v-model="post_data.accepted_electronic_payments"
                    :value="'Electronic cards'"
                    class="custom-control-input"
                    id="e_cards"
                  />
                  <label class="custom-control-label" for="e_cards"
                    >Electronic cards</label
                  >
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    v-model="post_data.accepted_electronic_payments"
                    :value="'Bank Transfer'"
                    class="custom-control-input"
                    id="b_transfer"
                  />
                  <label class="custom-control-label" for="b_transfer"
                    >Bank Transfer</label
                  >
                </div>
              </div>
              <label
                v-for="error in errors.accepted_electronic_payments"
                :key="error"
                class="form-control-flush text-danger text-sm"
                >{{ error }}</label
              >
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                @click="previousToBusiness"
                class="btn btn-block btn-md btn-default my-4"
              >
                previous
              </button>
            </div>
            <div class="col-6">
              <button
              :disabled="button_loader"
                type="button"
                @click="create_entity"
                class="btn btn-block btn-md btn-default my-4"
                :style="!button_loader?'':styleObject"
              >
                Register <i v-if="button_loader" class="fa fa-spinner fa-spin"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Entity information section -->

    <!--Start of  User information section -->
  </div>
</template>
<script>
import axios from "axios";
import { alert } from "../../utils/alertUtils";
import PhoneInput from "../../components/PhoneInput";

export default {
  name: "CreateEntity",
  components: {
    "phone-input": PhoneInput,
  },
  data() {
    return {
      post_data: {
        accepted_electronic_payments: [],
        phone: "",
      },
      user: {},
      phone: "",
      industry: "",
      industries: [],
      errors: {
        description: [],
        other_industry: [],
      },
      years: [],
      show_other: false,
      business_orientation: "",
      button_loader:false,
            styleObject: {
    cursor: 'not-allowed',
  }
    };
  },
  watch: {
    business_orientation: function (new_v) {
        if (new_v === "service") {
        this.post_data.is_product_related = false;
        this.post_data.is_service_related = true;
        this.post_data.no_of_products = 0;
        this.post_data.business_type = "Retailer";
        this.post_data.has_rbs_certificate = false;
        this.post_data.does_delivery = false;
        delete this.post_data.no_of_services;
      } else if (new_v === "product") {
        this.post_data.is_product_related = true;
        this.post_data.is_service_related = false;
        this.post_data.no_of_services = 0;
        delete this.post_data.no_of_products;
        delete this.post_data.business_type;
        delete this.post_data.has_rbs_certificate;
        delete this.post_data.does_delivery;
      } else if (new_v === "both") {
        this.post_data.is_product_related = true;
        this.post_data.is_service_related = true;
      }
    },
    industry: function (new_V) {
      if (new_V === "other") this.show_other = true;
      else {
        this.show_other = false;
        this.post_data.industry = new_V;
      }
    },
  },
  methods: {
    validate: function () {
      if (this.industry == "") {
        this.errors.industry = ["This field is required!"];
        return false;
      }
    },
    handle_entity_website: function (website) {
      this.post_data.website = website;
      if (!website.startsWith("http", 0)) {
        this.post_data.website.errors = [
          "Invalid URL for website, hint(http://...)",
        ];
      } else {
        this.post_data.website.errors = [];
      }
    },

    handle_phone: function (phone) {
      this.phone = phone;
    },
    handle_entity_name: function (name) {
      this.post_data.name = name;
      if (name.trim().length < 3) {
        this.post_data.name.errors = ["Name must be at least 3 character!"];
      } else {
        this.post_data.name.errors = [];
      }
    },
    handle_entity_address: function (address) {
      this.post_data.address = address;
    },
    get_industries: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/industries`)
        .then((res) => {
          console.log(res.data);
          this.industries = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    get_years: function () {
      for (
        var i = new Date().getFullYear();
        i > new Date().getFullYear() - 150;
        i--
      ) {
        this.years.push(i);
      }
    },
    create_entity: function () {
      this.button_loader = true;
      this.errors = {};
      var data = {
        entity: {
          name: this.post_data.name,
          email: this.post_data.email,
          phone: this.phone.full_format,
          address: this.post_data.address,
          website: this.post_data.website,
          description: this.post_data.description,
          no_of_products: this.post_data.no_of_products,
          no_of_services: this.post_data.no_of_services,
          no_of_employees: this.post_data.no_of_employees,
          no_of_women_employees: this.post_data.no_of_women_employees,
          does_delivery: this.post_data.does_delivery,
          industry: this.post_data.industry,
          is_rdb_registered: this.post_data.is_rdb_registered,
          target_market: this.post_data.target_market,
          has_rbs_certificate: this.post_data.has_rbs_certificate,
          accepted_electronic_payments: this.post_data
            .accepted_electronic_payments,
          business_type: this.post_data.business_type,
          starting_year: this.post_data.starting_year,
          is_product_related: this.post_data.is_product_related,
          is_service_related: this.post_data.is_service_related,
          about: this.post_data.about,
        },
      };
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/entities`;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.button_loader = false;
          console.log(res);
          alert.success_center("Entity added successfully.");

          this.text = "";
          this.post_data = {};
          this.user = {};
          this.errors = {};
          this.$router.push({
            name: "EntityUploadImages",
            params: { uuid: res.data.uuid },
          });
        })
        .catch((err) => {
          this.button_loader = false;
          console.error(err.response);
          if (err.response.status === 400) {
            this.errors = err.response.data;
            alert.error(
              "An error occured, check the form for specific errors!"
            );
          } else {
            alert.error(
              "An error occured please try again,if the error persist kindly contact the owner of this site!"
            );
          }
        });
    },
    nextToAdditional: function () {
      var BusinessInfo = document.getElementById("BusinessInfo");
      var AdditionalInfo = document.getElementById("AdditionalInfo");
      var step2 = document.getElementById("step-2");

      BusinessInfo.classList.add("d-none");
      step2.classList.add("active");
      AdditionalInfo.classList.remove("d-none");
    },

    previousToBusiness: function () {
      var BusinessInfo = document.getElementById("BusinessInfo");
      var AdditionalInfo = document.getElementById("AdditionalInfo");
      var step2 = document.getElementById("step-2");

      BusinessInfo.classList.remove("d-none");
      AdditionalInfo.classList.add("d-none");
      step2.classList.remove("active");
    },
  },
  created() {
    this.get_industries();
    this.get_years();
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  margin-top: 20px;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  float: left;
  width: 42.28%;
  position: relative;
  text-align: center;
  list-style: none;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li.active:before {
  border-color: #172a4d;
  background: #172a4d;
  color: white;
}
.progressbar li.active:after {
  background: #172a4d;
}
.progressbar li.active li:after {
  background: #172a4d;
}
.progressbar li.active li:before {
  border-color: #172a4d;
  background: #172a4d;
  color: white;
}
.progressbar li:first-child:after {
  content: none;
}

@media (max-width: 768px) {
  .progressbar li span {
    display: none;
  }
}
</style>